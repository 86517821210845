/**
 * Format a string "YYYY-MM-DD HH:MM:SS" to "DD/MM/YYYY HH:MM"
 */
export function asDate(datetime: string, withTime: boolean = false) {
    if (!datetime) {
        return '';
    }

    // Converti la date en format ISO 8601 si elle ne l'est pas déjà
    let newDatetime = `${datetime}`;
    if (datetime.split('T').length === 1) {
        let splittedDatetime = datetime.split(' ');
        newDatetime = `${splittedDatetime[0]}T${splittedDatetime[1] ?? '00:00:00'}+02:00`
    }

    let timestamp = Date.parse(newDatetime);

    if (Number.isNaN(timestamp)) {
        return datetime;
    }

    return new Date(timestamp).toLocaleDateString('fr', {
        timeZone: 'Europe/Paris',
        hour: withTime ? '2-digit' : undefined,
        minute: withTime ? '2-digit' : undefined
    });
}
